<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('users/add')"
        />
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Angestellte Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="Search By ID"
            filterMatchMode="contains"
          />
        </template>
        <template #body="slotProps">
          {{ slotProps.data.id }}
          <Button
            type="button"
            @click="addWorkDay(slotProps.data.id)"
            icon="pi pi-sign-in"
            v-if="slotProps.data.id != 1 && isLogin(slotProps.data.id) == false"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button>
          <Button
            type="button"
            @click="updateWorkDay(slotProps.data.id)"
            icon="pi pi-sign-out"
            v-if="
              slotProps.data.id != 1 &&
              isLogin(slotProps.data.id) == true &&
              isLoginList.find((el) => el.adminsId.id == slotProps.data.id).signOut == '0:00'
            "
            class="p-button-danger ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
      <Column filterMatchMode="contains" field="image" header="Image">
        <template #body="slotProps">
          <img
            :src="$baseUploadURL + slotProps.data.image"
            class="profile-pic height-150 width-150 align-items-center"
            style="width: 100px; height: 100px"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="fullName"
        header="Name"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['fullName']"
            class="p-column-filter"
            placeholder="Search By Name"
            filterMatchMode="contains"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="phone"
        header="Telefon"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="Search By Telefon"
            filterMatchMode="contains"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="email"
        header="Email"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="email"
            v-model="filters['email']"
            class="p-column-filter"
            placeholder="Search By Email"
            filterMatchMode="contains"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="id"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('users/days/' + slotProps.data.id)"
            icon="pi pi-chart-bar"
            v-if="slotProps.data.id != 1"
            class="p-button-info mr-2 p-button-rounded"
          ></Button>

          <Button
            type="button"
            @click="$router.push('users/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            class="p-button-success p-mr-2 p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="slotProps.data.id != 1"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      isLoginList: [],
    };
  },
  methods: {
    isLogin(id) {
      let re = false;
      const one = this.isLoginList.find((el) => el.adminsId.id == id);
      if (one) {
        re = true;
      }

      return re;
    },
    isWorkDayAdd() {
      const duration = moment(new Date());

      const date = duration.format("DD-MM-YYYY");
      const month = duration.format("MM-YYYY");

      this.$http
        .post(`payroll/search`, {
          date,
          month,
        })
        .then((res) => {
          this.isLoginList = res.data.data.items;
        });
    },
    addWorkDay(id) {
      const duration = moment(new Date());

      const date = duration.format("DD-MM-YYYY");
      const month = duration.format("MM-YYYY");
      const signIn = duration.format("H:mm");

      this.$http
        .post(`payroll`, {
          signIn,
          date,
          month,
          adminsId: id,
        })
        .then(
          (response) => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },

    updateWorkDay(id) {
      const duration = moment(new Date());

      const date = duration.format("DD-MM-YYYY");
      const month = duration.format("MM-YYYY");
      const signOut = duration.format("H:mm");


      const one = this.isLoginList.find((el) => el.adminsId.id == id);
      this.$http
        .put(`payroll/${one.id}`, {
          signOut,
        })
        .then(
          (response) => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    getData() {
      this.$http.get(`admins`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;

          this.isWorkDayAdd();
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.delete(`admins/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
